import * as React from "react";
import { LayoutProvider, Wrapper } from "../components/layout";
import { useViewPortSize } from "../hooks";
import { ErrorMessage, Locally } from "../components/sections";

const NotFoundPage = () => {
  //Hooks
  const [isMobile, isTablet] = useViewPortSize();

  React.useEffect(() => {
    window.location.href = "/";
  }, []);
  
  return (
    <LayoutProvider seo={false}>
      <div style={{ marginTop: "-8px", marginBottom: "-18px" }}>
        <Locally />
      </div>
    </LayoutProvider>
  );
};

export default NotFoundPage;
